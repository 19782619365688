import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/Footer';
import HomePage from './components/HomePage/HomePage';
import About from './components/About/About';
import Team from './components/Team';
import F404 from './components/F404';
import Activities from './components/Activities/Activities';
// import Collaborators from './components/Collab';
import JoinUs from './components/JoinUs';
import Signin from './components/Dashboard/Signin';
import Main from './components/Dashboard/Main';
import Certificate from './components/Certificate';
import QuestionPage from './components/Questions/QuestionPage';
import Modal from './components/Modal';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // useEffect(() => {
  //   if (window.location.pathname === '/reel-event-doc') {
  //     window.location.href =
  //       'https://docs.google.com/document/d/10V1xxYHLeE1xG-36K4n3yvx-EW4BMZc5ba_WC2hqavY/edit?usp=sharing';
  //   }
  //   if (window.location.pathname === '/reel-event') {
  //     window.location.href = 'https://forms.gle/BwvWNuWa7cpayf7s8';
  //   }
  // }, [window.location.pathname]);

  return (
    <div className='snap-both'>
      <Navbar />
      {/* 
      <div className='fixed inset-x-0 lg:bottom-2 bottom-12 p-4 z-50'>
        <div className='rounded-md bg-primary px-4 py-5 text-white shadow-lg'>
          <p className='text-center text-[16px] font-bold'>
            Join Diplomacia core team:&nbsp;{' '}
            <a
              href='https://forms.gle/cmLZzXVrcfrZqrBZ9'
              className='inline-block underline'
              target='_blank'
            >
              Fill the form!
            </a>
          </p>
        </div>
      </div>
      */}
      {/* <Modal /> */}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/team' element={<Team />} />
        <Route path='/activities' element={<Activities />} />
        <Route
          path='/join-us'
          element={<JoinUs link='https://forms.gle/8rYVdSgnoTsoEBTx7' />}
        />
        <Route
          path='/ppt-event'
          element={<JoinUs link='https://forms.gle/z46J71dWxhXe5apx9' />}
        />
        <Route
          path='/bol-event'
          element={<JoinUs link='https://forms.gle/eFvzxhq3gAJjNw538' />}
        />
        <Route
          path='/join-core-team'
          element={<JoinUs link='https://forms.gle/YkJVLZs3jDUMHh3N7' />}
        />
        <Route
          path='/delegations'
          element={<JoinUs link='https://forms.gle/7K5GBNtsXsfKT7KB6' />}
        />
        <Route path='/signin' element={<Signin />} />
        <Route path='/dashboard' element={<Main />} />
        <Route path='/cert' element={<Certificate />} />
        <Route path='/cert/:id' element={<Certificate />} />
        <Route path='*' element={<F404 />} />
        <Route path='/Questions' element={<QuestionPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
