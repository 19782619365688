import React from 'react';
import Events from '../Events';
import FAQs from '../FAQs';
import HeroSection from '../HeroSection';
import LogoSection from '../LogoSection';
import StudentTestimonials from '../StudentTestimonials';
import Testimonials from '../Testimonials';
import Achievements from '../Achievements';
import { setActiveNav } from '../Navbar';
import { useEffect } from 'react';

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <LogoSection />
      <Events />
      <Testimonials />
      <StudentTestimonials/>
      <Achievements />
      <FAQs/>
    </>
  );
};

export default HomePage;
