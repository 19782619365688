import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import Down from './common/Down';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../styles/index.css';

const achievementsData = [
  {
    image: require('../assets/achievements/high-commendation-delegate.jpg'),
    name: 'Delegate 1',
    award: 'High Commendation',
    event: 'International Model United Nations 2024',
    committee: 'United Nations Security Council',
    description:
      'Recognized for exceptional diplomatic skills and innovative problem-solving approaches',
  },
  {
    image: require('../assets/achievements/honorable-mention-delegate.jpg'),
    name: 'Delegate 2',
    award: 'Honorable Mention',
    event: 'Global MUN Summit 2024',
    committee: 'Human Rights Council',
    description:
      'Demonstrated outstanding leadership and negotiation abilities',
  },
  {
    image: require('../assets/achievements/best-delegate-award.jpg'),
    name: 'Delegate 3',
    award: 'Best Delegate',
    event: 'Regional Diplomacy Conference 2024',
    committee: 'Economic and Social Council',
    description:
      'Outstanding contribution in policy formulation and international cooperation',
  },
  {
    image: require('../assets/achievements/special-mention-delegate.jpg'),
    name: 'Delegate 4',
    award: 'Special Mention',
    event: 'Youth Diplomacy Forum 2024',
    committee: 'Climate Action Committee',
    description:
      'Exceptional advocacy for environmental sustainability and climate action',
  },
];

const Achievements = () => {
  return (
    <>
      <div className='-skew-y-3 h-40 w-full bg-green-300'></div>
      <section id='achievements' className='bg-green-300 pb-1 -mt-24 relative'>
        <div className='mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-8'>
          <h2 className='text-center text-gray-700 text-4xl my-8 font-bold tracking-tight sm:text-5xl'>
            Student Achievements
          </h2>

          <div className='py-8 px-4 relative'>
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
              spaceBetween={30}
              className='achievement-swiper !pb-12'
              loop={true}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              speed={800}
            >
              {achievementsData.map((achievement, index) => (
                <SwiperSlide key={index} className='py-4'>
                  <div className='bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 h-[550px] flex flex-col'>
                    <div className='relative h-80 w-full'>
                      <img
                        src={achievement.image}
                        alt={`${achievement.name}'s Achievement`}
                        className='w-full h-full object-cover object-top'
                      />
                      <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent p-4'>
                        <h3 className='text-white text-xl font-bold'>
                          {achievement.name}
                        </h3>
                        <p className='text-emerald-300 font-semibold'>
                          {achievement.award}
                        </p>
                      </div>
                    </div>
                    <div className='p-6 flex-1 flex flex-col'>
                      <div className='mb-4'>
                        <h4 className='text-lg font-semibold text-gray-800'>
                          {achievement.event}
                        </h4>
                        <p className='text-emerald-600 font-medium'>
                          {achievement.committee}
                        </p>
                      </div>
                      <p className='text-gray-600'>{achievement.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Down href='FAQ' />
      </section>
    </>
  );
};

export default Achievements;
